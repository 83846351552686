@import '../../styles/variables.scss';

.brand {
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	padding: 0;
	font-family: 'Playfair Display', Georgia, serif;
	font-size: 1.25rem;
	font-weight: 700;
	cursor: pointer;
}

.toggle {
	border: none;
}

.darktoggle {
	display: flex;
	align-items: center;
	padding: 0 0.5rem;
	@include media-breakpoint-down(md) {
		padding: 0;
	}
}

.logo {
	width: 2rem;
	height: 2rem;
}

.icon {
	width: 1.5rem;
	height: 1.5rem;
	path {
		fill: white;
	}
}

.iconinverted {
	width: 1.5rem;
	height: 1.5rem;
	path {
		fill: black;
	}
}

.link:after {
	background-color: $white;
}

.unstyled:after {
	background: none;
}

.leftNav {
	@include media-breakpoint-down(md) {
		flex-direction: row;
		> a:not(:first-child) {
			flex: 1;
			margin-left: 1rem;
		}
		> span {
			margin-right: -0.5rem;
		}
	}
}
