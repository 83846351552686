@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Playfair+Display:ital,wght@0,400;0,700;1,400&display=swap');
@import './variables.scss';

body {
	margin: 0;
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

main,
section {
	scroll-margin: $navbar-height 0 0 0;

	@include media-breakpoint-down(md) {
		scroll-margin: $navbar-height-collapsed 0 0 0;
	}
}

main {
	overflow-x: hidden;
}

section {
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	min-height: calc(#{$jumbotron-height} - #{$navbar-height});
	padding-bottom: $navbar-height;

	@include media-breakpoint-down(md) {
		min-height: calc(#{$jumbotron-height} - #{$navbar-height-collapsed});
		padding-bottom: $navbar-height-collapsed;
	}

	p {
		margin-bottom: 2rem;
		font-size: $font-size-base;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

label {
	display: flex;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Playfair Display', Georgia, serif;
	font-weight: 700;
	margin-bottom: 0;
}

input:-moz-ui-invalid,
textarea:-moz-ui-invalid {
	border-color: $danger;
	box-shadow: 0 0 0 0.2rem rgba($danger, 0.25);
}

/**
 * Bootstrap customizations
 */
.navbar {
	&-nav {
		font-size: 0.825rem;
		text-transform: uppercase;
	}

	&-toggler {
		padding: 0;

		&-icon {
			display: inline-block;
			width: 1.25rem;
			height: 1.25rem;
		}
	}
}

.nav-link {
	position: relative;

	@include media-breakpoint-down(md) {
		width: fit-content;
	}

	&:after {
		content: '';
		position: absolute;
		height: 1px;
		bottom: 0.5rem;
		left: 0.5rem;
		right: 0.5rem;

		@include media-breakpoint-down(md) {
			left: 0;
			right: 0;
		}

		background-color: $dark;
		visibility: hidden;
		transform: scaleX(0);
		transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	}

	&:hover:after,
	&:focus:after {
		visibility: visible;
		transform: scaleX(1);
	}
}

.active:after {
	visibility: visible;
	transform: scaleX(1);
}

.jumbotron {
	display: flex;
	align-items: center;
	justify-content: center;
	height: $jumbotron-height;
	margin-bottom: 0;
}

.carousel {
	width: 100vw;

	/* BS application of transform: none breaking their own animation in BS v4, so overriding */
	&-fade.carousel-fade .carousel-item {
		transition: opacity 0.6s cubic-bezier(0.6, -0.28, 0.74, 0.05) !important;
	}

	&-item {
		height: $jumbotron-height;
	}

	&-caption {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 100%;
		bottom: 0;
	}
}

.tooltip {
	font-family: 'Lato', 'Roboto', 'Helvetica Neue', sans-serif;
}

.form-control:focus {
	border-color: $dark;
	box-shadow: 0 0 0 0.2rem rgba($dark, 0.25);
}

/**
 * Global styles
 */
.transition {
	transition: background-color 250ms ease-in-out, color 250ms ease-in-out;
}