.span {
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 5px;
	opacity: 0.5;
	margin-bottom: 1rem;
}

.header {
	font-size: 1.25rem;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 5px;
	margin-bottom: 4rem;
}
