.body {
	height: 79vh;
	@media (max-width: 450px) {
		height: 60vh;
	}
	display: grid;
	padding: 0;
	background-color: rgb(208, 201, 186);
	grid-template-columns: 1.3274fr 2.2123fr 2.4778fr 2.4778fr 3.8938fr 2.4778fr 26.5716fr 2.4778fr 3.5398fr 2.3008fr 11.7044fr 2.4778fr 10.4424fr 2.4778fr 10.6194fr 2.30088fr 2.83185fr 1.9469fr 1.5929fr 2.4778fr 1.7699fr;
	grid-template-rows: 1.6666fr 2.2222fr 21.1111fr 2.2222fr 16.6666fr 2.2222fr 7.7777fr 2.2222fr 17.7777fr 2.2222fr 15.5555fr 2.2222fr 1.1111fr 1.6666fr 3.3333fr;
}

.shadow {
	box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.15);
}

.button  {
  position: absolute;
  padding: 0.5rem;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}
