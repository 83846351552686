.moon {
	height: 1.5rem;
	width: 1.5rem;
	transform: rotate(45deg);
	transition: all 250ms ease-in-out;
	cursor: pointer;
}

.sun {
	height: 1.5rem;
	width: 1.5rem;
	transform: rotate(45deg);
	transition: all 250ms ease-in-out;
	cursor: pointer;
}
