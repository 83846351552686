@import '../../styles/variables.scss';

.image {
	box-shadow: $box-shadow-sm;
	border-radius: $border-radius;
	width: 50%;
	background-repeat: no-repeat;
	background-size: cover;
  background-position: center center;
}

.invert:after {
  background: $white;
}
