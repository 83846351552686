/**
 * Bootstrap variable overrides
 */
$theme-colors: (
	'custom-khoros': #00004b,
	'custom-brandly': #ea1947,
	'custom-tint': #fa5a3a,
	'custom-stitch-fix': #21beb0,
	'custom-frb': #0a382b,
	'custom-digifx': #75bd4c,
	'custom-soundtrack': #404df2
);

$font-family-sans-serif: 'Lato', 'Roboto', 'Helvetica Neue', sans-serif;

@import '~bootstrap/scss/bootstrap';

/**
 * Custom variables
 */
$navbar-height: 3.5rem;
$navbar-height-collapsed: 3.125rem;
$jumbotron-height: 100vh;